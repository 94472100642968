import moment from 'moment'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { getLenta, getLentaID, getPageMenuID, getPhotoByID } from '../../API'
import PageLoader from '../../Sceleton/PageLoader'

import Slider from 'react-slick'

var formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'UZS',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export default class Item extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: [],
      img: [],
      loading: true,
      pageid: this.props.match.params.id,
      data: {
        id: 19,
        product_name: 'weqe123123',
        category_name: 'маҳсулотлар',
        category_id: 1,
        contact_name: 'qweqweqweq',
        email: 'weq@mqil.ri',
        phone: '998213212313',
        photo: "{'ff8081817db3b39e6719e567'}",
        note: 'qweqwewqe123123',
        file: null,
        location: 'qwdqwe13123',
        price: 1100,
        attributes: {
          type: 'json',
          value: '{}',
        },
      },
    }
  }

  componentDidMount() {
    this.props.changeMenuTrue()
    getPageMenuID(this, this.props.match.params.id)
    getPhotoByID(this, this.props.match.params.id)
    this.setState({ pageid: this.props.match.params.id })
    getLentaID(this, this.props.match.params.id)
    setTimeout(() => this.setState({ loading: false }), 1200)
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.setState({ loading: true, pageid: nextProps.match.params.id })
      //   getLentaID(this, nextProps.match.params.id)
      getPageMenuID(this, nextProps.match.params.id)
      getPhotoByID(this, this.props.match.params.id)
      setTimeout(() => this.setState({ loading: false }), 1200)
    }
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
    }
    return (
      <div className="page">
        <div className="container">
          <div className="page_item_block">
            <div className="row">
              <div className="lenta_item_d">
                <Slider {...settings}>
                  {this.state.img.map((item) => (
                    <div className={'slider_item'}>
                      <img
                        src={
                          `https://api.cabinet.aziz-ayol.uz/uploads/files/` +
                          item
                        }
                        alt=""
                      />
                    </div>
                  ))}
                </Slider>

                <p>{this.state.data.product_name}</p>
                <div className="price">
                  {formatter.format(this.state.data.price)}
                </div>
                <span>{this.state.data.category_name}</span>
                <div className="tt">Tavsif</div>
                <h3>{this.state.data.note}</h3>
                <div className="contacts">
                  <div className="l1">{this.state.data.phone_formated}</div>
                </div>
              </div>
              <div className="lenta_user">
                <div className="ll_m">
                  <div className="l_header">Foydalanuvchi</div>
                  <div className="l_user_r">
                    <img
                      className={'user_i'}
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"
                      alt=""
                    />
                    <div className="l_info">
                      <p>{this.state.data.contact_name}</p>
                      <span>{this.state.data.email}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
