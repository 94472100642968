import React from 'react'
import ContentLoader from 'react-content-loader'

const PageLoader = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 100% 100%"
      height={800}
      width={'80%'}
      {...props}
    >
      <rect x="200" y="210" rx="5" ry="5" width="100%" height="10" />
      <rect x="200" y="0" rx="5" ry="5" width="100%" height="200" />
      <rect x="200" y="230" rx="5" ry="5" width="100%" height="200" />
      <rect x="200" y="450" rx="5" ry="5" width="100%" height="200" />
    </ContentLoader>
  )
}

export default PageLoader
