import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { getMenu } from '../../API'

export default class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: [],
      search: '',
    }
  }

  componentDidMount() {
    getMenu(this)
  }

  render() {
    return (
      <div className="navigation">
        <div className="container">
          <div className="navigation_search">
            <input
              onChange={(e) => this.setState({ search: e.target.value })}
              type="text"
              placeholder={'Portal ichida qidiruv'}
            />
          </div>
          <div className="row">
            {this.state.menu
              .sort((a, b) => a.list_number - b.list_number)
              .filter((item) => item.parent_id == null)
              .map((item) => (
                <div className="col">
                  <p>{item.name}</p>
                  <ul>
                    {this.state.menu
                      .filter(
                        (item2) =>
                          item2.parent_id == item.id &&
                          item2.name.includes(this.state.search),
                      )
                      .map((item2) => (
                        <li>
                          <NavLink
                            onClick={() => this.props.changeMenuTrue()}
                            onClick={() => this.props.closeNavigation()}
                            to={'/page/' + item2.id}
                            activeClassName="active"
                          >
                            {item2.name}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
          </div>
          <div className="navigation_bottom">
            <a href="#" onClick={() => this.props.closeNavigation()}>
              menyni yopish
            </a>
          </div>
        </div>
      </div>
    )
  }
}
