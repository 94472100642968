import moment from 'moment'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { getLenta, getPageMenuID } from '../../API'
import PageLoader from '../../Sceleton/PageLoader'

var formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'UZS',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export default class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: [],
      loading: true,
      pageid: this.props.match.params.id,
      lenta: [],
    }
  }

  componentDidMount() {
    this.props.changeMenuTrue()
    getPageMenuID(this, this.props.match.params.id)
    this.setState({ pageid: this.props.match.params.id })
    if (this.props.match.params.id == 34) {
      getLenta(this)
    }
    setTimeout(() => this.setState({ loading: false }), 1200)
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.setState({ loading: true, pageid: nextProps.match.params.id })
      if (nextProps.match.params.id == 34) {
        getLenta(this)
      }
      getPageMenuID(this, nextProps.match.params.id)
      setTimeout(() => this.setState({ loading: false }), 1200)
    }
  }

  render() {
    return (
      <div className="page">
        <div className="container">
          {this.state.loading ? (
            <div style={{ height: 'calc(100vh - 250px)' }}>
              <PageLoader />
            </div>
          ) : (
            <div className="row">
              {this.state.page.length > 1 ? (
                this.state.page.map((item) => (
                  <div className="page_block">
                    <div className="page_category">{item.category_name}</div>
                    <div className="page_title">
                      {' '}
                      <NavLink to={'/pagenews/' + item.id}>
                        {' '}
                        {item.name}{' '}
                      </NavLink>
                    </div>
                    <div className="page_content">
                      <span className="m0">{item.title}</span>

                      <div className="page_date">
                        {moment(item.date, 'YYYY-MM-DD').format(
                          'MMMM DD, YYYY',
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : this.state.pageid == 34 ? (
                <div>
                  <div className="container_header">Mahsulot va xizmatlar</div>
                  <div className="lenta">
                    <div className="row">
                      {this.state.lenta.map((item) => (
                        <div className="col-3">
                          <NavLink
                            to={'/item/' + item.id}
                            className="lenta_item"
                          >
                            <img
                              src={
                                `https://api.cabinet.aziz-ayol.uz/uploads/files/` +
                                item.photo
                                  .split(',')[0]
                                  .replace(/(^\{+|\}+$)/gm, '')
                                  .replace(/(^\'+|\'+$)/gm, '')
                              }
                              alt=""
                            />
                            <p>{item.product_name}</p>
                            <span>{item.category_name}</span>
                            <h3>{item.note}</h3>
                            <div className="price_m">
                              {formatter.format(item.price)}
                            </div>
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : this.state.page.length === 1 ? (
                this.state.page.map((item) => (
                  <div>
                    <div className="page_category">{item.category_name}</div>
                    <div className="page_title">{item.name}</div>
                    <div className="page_content">
                      {item.image == null ? null : (
                        <img className="img" src={item.image} />
                      )}
                      <span>{item.title}</span>
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        ></div>
                      </p>
                      <div className="page_date">
                        {moment(item.date, 'YYYY-MM-DD').format(
                          'MMMM DD, YYYY',
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: 'calc(100vh - 250px)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <h3 style={{ opacity: 0.7, fontWeight: 400 }}>Havola yo'q</h3>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}
