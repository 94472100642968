import moment from 'moment'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { getPageID, getPageMenuID } from '../../API'
import bg1 from '../../assets/bg1.png'
import PageLoader from '../../Sceleton/PageLoader'

export default class Error extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <div className="page">
        <div className="container">
          {this.state.loading ? (
            <div style={{ height: 'calc(100vh - 250px)' }}>
              <PageLoader />
            </div>
          ) : (
            <div className="row">
              <div
                style={{
                  width: '100%',
                  height: 'calc(100vh - 250px)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h3 style={{ opacity: 0.7, fontWeight: 400 }}>404</h3>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
