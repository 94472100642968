import React, { Component } from 'react'
import bg1 from '../../assets/bg1.png'

export default class Report extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className="report">
        <div className="container">
          <div className="row">
            <div className="repoert_title">Murojaat</div>
            <form action="">
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Murojaat matni kiriting..."
              ></textarea>
              <div className="from_check">
                <input type="checkbox"></input>
                <p>
                  Foydalanish <span>qoidalari</span> bilan tanishdim
                </p>
              </div>
              <div className="form_bottom">
                <button className="form_button_send">Jo`natish</button>
                <button className="form_button_cancel">Orqaga</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
