import moment from 'moment'
import React, { Component } from 'react'
import { IoMail, IoPaperPlane } from 'react-icons/io5'
import { NavLink } from 'react-router-dom'

export default class Item extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className="item">
        <p>
          <NavLink to={'/pagenews/' + this.props.id}>
            {this.props.title}
          </NavLink>
        </p>
        <span>
          {' '}
          {moment(this.props.date, 'YYYY-MM-DD').format('MMMM DD, YYYY')}
        </span>
      </div>
    )
  }
}
