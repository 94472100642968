import moment from 'moment'
import React, { Component } from 'react'
import {
  FiHome,
  FiPackage,
  FiInfo,
  FiClipboard,
  FiPhone,
  FiCalendar,
  FiHeart,
  FiTag,
  FiAlertCircle,
} from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { getAllPages, getLenta } from '../../API'

import play from '../../assets/play.png'

import iamge1 from '../../assets/iamge1.png'
import video3 from '../../assets/video3.png'
import video2 from '../../assets/video2.png'
import video1 from '../../assets/video1.png'
import video from '../../assets/video/video.mp4'
import ph1 from '../../assets/ph1.jpeg'
import Item from '../../Components/News/Item'
import HomeLoader from '../../Sceleton/HomeLoader'
import NewsLoader from '../../Sceleton/NewsLoader'

import { Modal } from 'react-responsive-modal'

var formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'UZS',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pages: [{ title: null, date: null, image: null, news: null }],
      loading: true,
      scrolling: false,
      transform: null,
      setOpen: false,
      lenta: [],
    }
  }

  componentDidMount() {
    this.props.changeMenuFalse()
    getAllPages(this)
    getLenta(this)
    setTimeout(() => this.setState({ loading: false }), 1200)
    window.addEventListener('scroll', this.handleScroll)
  }

  onOpenModal() {
    this.setState({
      setOpen: true,
    })
  }
  onCloseModal() {
    this.setState({
      setOpen: false,
    })
  }

  render() {
    return (
      <div className="home">
        <div className="video_mackop"></div>
        <div className="video">
          <video
            loop="true"
            autoplay="autoplay"
            controls="controls"
            id="vid"
            muted
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="slider">
          <div className="container">
            <div className="slider_left">
              <h1>
                Xotin-qizlar portaliga <br></br>xush kelibsiz
              </h1>
              <p>Onlayn xizmatlar</p>
            </div>
            <div className="slider_right">
              <ul>
                <li style={{ color: 'red' }}>
                  <a
                    href="https://aziz-ayol.uz/page/28"
                    style={{ color: 'red' }}
                  >
                    Murojaat yuborish
                  </a>
                </li>
                <li>
                  <a href="https://cabinet.aziz-ayol.uz/public/forms/user_registration?locale=la">
                    Ro`yxatdan o`tish
                  </a>
                </li>
              </ul>
              {/* <ul>
                <li>
                  <NavLink to="/page/1.1">Pullik xizmatlar</NavLink>
                </li>
                <li>
                  <NavLink to="/page/1.1">Yagona telefon raqami</NavLink>
                </li>
              </ul> */}
              {/* <ul>
                <li>
                  <NavLink to="/page/1.1">Tibbiy maslahat olish</NavLink>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="container_header">So`ngi yangiliklar</div>
          {this.state.loading ? (
            <div className="row">
              <div className="col">
                <HomeLoader />
              </div>
              <div className="col">
                <NewsLoader />
                <NewsLoader />
              </div>
              <div className="col">
                <NewsLoader />
                <NewsLoader />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col">
                {this.state.pages
                  .filter((item) => item.news == true)
                  .slice(0, 1)

                  .map((item) => (
                    <div className="main_item">
                      <img src={item.image} />
                      <p>
                        <NavLink to={'/pagenews/' + item.id}>
                          {' '}
                          {item.title}{' '}
                        </NavLink>
                      </p>
                      <span>
                        {moment(item.date, 'YYYY-MM-DD').format(
                          'MMMM DD, YYYY',
                        )}
                      </span>
                      {/* OKTYABR 01, 2021 • elonlar */}
                    </div>
                  ))}
              </div>
              <div className="col">
                <div className="news">
                  {this.state.pages
                    .filter((item) => item.news == true)
                    .slice(0, 5)

                    .map((item) => (
                      <Item id={item.id} title={item.title} date={item.date} />
                    ))}
                </div>
              </div>
              <div className="col">
                <div className="news">
                  {this.state.pages
                    .filter((item) => item.news == true)
                    .slice(5, 10)

                    .map((item) => (
                      <Item id={item.id} title={item.title} date={item.date} />
                    ))}
                </div>
              </div>
            </div>
          )}
          <div className="container_header">Mediateka</div>
          <div className="media">
            <div className="row">
              <div className="col-6">
                <div className="item_big">
                  <div className="media_img_bg"></div>
                  <img src={ph1} className={'media_img'} alt="" />
                  <div className="text">
                    <p>
                      Mahalla yoshlari o’rtasida sportning shaxmat turi bo’yicha
                      Buxoro viloyati prokurori kubogi
                    </p>
                    <span>sentyabr 09, 2021</span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <div className="item" onClick={() => this.onOpenModal()}>
                      <div className="media_img_bg_2"></div>
                      <img src={video1} className={'media_img_2'} alt="" />
                      <img src={play} alt="" className={'play'} />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="item" onClick={() => this.onOpenModal()}>
                      <div className="media_img_bg_2"></div>
                      <img src={video2} className={'media_img_2'} alt="" />
                      <img src={play} alt="" className={'play'} />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="item" onClick={() => this.onOpenModal()}>
                      <div className="media_img_bg_2"></div>
                      <img src={video3} className={'media_img_2'} alt="" />
                      <img src={play} alt="" className={'play'} />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="item" onClick={() => this.onOpenModal()}>
                      <div className="media_img_bg_2"></div>
                      <img src={video3} className={'media_img_2'} alt="" />
                      <img src={play} alt="" className={'play'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container_header">Mahsulot va xizmatlar</div>
          <div className="lenta">
            <div className="row">
              {this.state.lenta.map((item) => (
                <div className="col-3">
                  <NavLink to={'/item/' + item.id} className="lenta_item">
                    <img
                      src={
                        `https://api.cabinet.aziz-ayol.uz/uploads/files/` +
                        item.photo
                          .split(',')[0]
                          .replace(/(^\{+|\}+$)/gm, '')
                          .replace(/(^\'+|\'+$)/gm, '')
                      }
                      alt=""
                    />
                    <p>{item.product_name}</p>
                    <span>{item.category_name}</span>
                    <h3>{item.note}</h3>
                    <div className="price_m">
                      {formatter.format(item.price)}
                    </div>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Modal
          className={'modal_coutom'}
          center
          open={this.state.setOpen}
          onClose={() => this.onCloseModal()}
        >
          <div className="modal_content">
            <video loop="true" autoplay="autoplay" id="vid" muted>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Modal>
      </div>
    )
  }
}
