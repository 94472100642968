import moment from 'moment'
import React, { Component } from 'react'
import { getPageID } from '../../API'
import bg1 from '../../assets/bg1.png'
import PageLoader from '../../Sceleton/PageLoader'

export default class PageNews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: [],
      loading: true,
    }
  }

  componentDidMount() {
    this.props.changeMenuTrue()
    getPageID(this, this.props.match.params.id)
    setTimeout(() => this.setState({ loading: false }), 1200)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.setState({ loading: true })
      getPageID(this, nextProps.match.params.id)
      setTimeout(() => this.setState({ loading: false }), 1200)
    }
  }

  render() {
    return (
      <div className="page">
        <div className="container">
          {this.state.loading ? (
            <PageLoader />
          ) : (
            <div className="row">
              <div className="page_category">
                {this.state.page.category_name}
              </div>
              <div className="page_title">{this.state.page.name}</div>
              <div className="page_content">
      
                <span>{this.state.page.title}</span>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.page.description,
                    }}
                  ></div>
                </p>
                <div className="page_date">
                  {' '}
                  {moment(this.state.page.date, 'YYYY-MM-DD').format(
                    'MMMM DD, YYYY',
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
