import React, { Component } from 'react'
import { IoMail, IoPaperPlane } from 'react-icons/io5'
import { NavLink } from 'react-router-dom'
import { getMenu } from '../../API'
import logo from '../../assets/logo.png'
import realsoft from '../../assets/realsoft.png'

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: [],
    }
  }

  componentDidMount() {
    getMenu(this)
  }
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col mr-100">
              <div className="footer_logo">
                <img src={logo} alt="" />
                <div>
                  <p>Aziz Ayol</p>
                  <span>Xotin-qizlar portali</span>
                </div>
              </div>
              <p>
                Ma`lumotlarni foydalanilganda aziz-ayol.uz ga havola ko`rsatish
                shart.
              </p>

              <p>© 2021 «Aziz Ayol» — xotin-qizlar portali.</p>
              <p>
                Ishonch telefoni:{' '}
                <span>
                  <a
                    href="tel:1146"
                    style={{ color: 'white', fontWeight: 'bold' }}
                  >
                    1146
                  </a>
                </span>
              </p>
              <a href="https://realsoft.uz" target="_blank">
                <img className="realsoft_logo" src={realsoft} alt="" />
              </a>
            </div>

            {this.state.menu

              .sort((a, b) => a.list_number - b.list_number)

              .filter((item) => item.parent_id == null)
              .slice(0, 3)
              .map((item) => (
                <div className="col">
                  <p>{item.name}</p>
                  <ul>
                    {this.state.menu
                      .filter((item2) => item2.parent_id == item.id)
                      .map((item2) => (
                        <li>
                          <NavLink
                            to={'/page/' + item2.id}
                            activeClassName="active"
                          >
                            {item2.name}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}
