import axios from 'axios'

const API = 'https://azizayoladmin.academytable.ru/api/v1/'

export async function getMenu(component) {
  axios
    .get('https://azizayoladmin.academytable.ru/api/v1/menu/')
    .then((response) => response.data)
    .then((data) => {
      console.log(data)
      component.setState({ menu: data })
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getAllPages(component) {
  axios
    .get('https://azizayoladmin.academytable.ru/api/v1/pageall/?ordering=-id')
    .then((response) => response.data)
    .then((data) => {
      console.log(data)
      component.setState({ pages: data })
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getLenta(component) {
  axios
    .get(
      'https://api.cabinet.aziz-ayol.uz/api/v1/advertisements?locale=la&token=0914ca58-b9ea-4842-9b0c-224c1830af44',
    )
    .then((response) => response.data)
    .then((data) => {
      console.log(data)
      component.setState({ lenta: data.data })
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getLentaID(component, id) {
  axios
    .get(
      `https://api.cabinet.aziz-ayol.uz/api/v1/public/advertisement?locale=la&id=${id}&token=0914ca58-b9ea-4842-9b0c-224c1830af44`,
    )
    .then((response) => response.data)
    .then((data) => {
      console.log(data)
      component.setState({ data: data.data })
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getPhotoByID(component, id) {
  axios
    .get(
      `https://api.cabinet.aziz-ayol.uz/web/v1/public/forms/f_advertisement/${id}/`,
    )
    .then((data) => {
      console.log('Photo ' + data.data.formData.photo)
      component.setState({ img: data.data.formData.photo })
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getPageID(component, id) {
  axios
    .get(`https://azizayoladmin.academytable.ru/api/v1/pageid/${id}/`)
    .then((response) => response.data)
    .then((data) => {
      console.log(data)
      component.setState({ page: data[0] })
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getPageMenuID(component, id) {
  axios
    .get(`https://azizayoladmin.academytable.ru/api/v1/page/${id}/`)
    .then((response) => response.data)
    .then((data) => {
      console.log(data)
      component.setState({ page: data })
    })
    .catch((error) => {
      console.log(error)
    })
}
