import React, { Component } from 'react'
import { IoMenu, IoChevronDown } from 'react-icons/io5'
import { NavLink } from 'react-router-dom'
import { getMenu } from '../../API'
import logo from '../../assets/logo.png'

export default class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: [],
      lang: false,
    }
  }

  componentDidMount() {
    getMenu(this)
  }

  render() {
    return (
      <div className={'menu '} id={this.props.theme}>
        <div className="container">
          <div className="row">
            <NavLink
              onClick={() => this.props.changeMenuFalse()}
              to={'/'}
              className="menu_logo"
            >
              <img src={logo} alt="" />
              <div>
                <p>Aziz Ayol</p>
                <span>Xotin-qizlar portali</span>
              </div>
            </NavLink>
            <div className="menu_button">
              <a onClick={() => this.props.openNavigation()}>
                <IoMenu size={20} />
              </a>
            </div>
            <div className="menu_nav">
              {/* <IoChevronDown size={12} /> */}
              {/* <div className="sub_menu">
                    <ul>
                      <li>
                        <a href="">Subcategory 1</a>
                      </li>
                
                    </ul>
                  </div> */}
              <ul>
                {this.state.menu
                  .sort((a, b) => a.list_number - b.list_number)
                  .filter((item) => item.parent_id == null)
                  .map((item) => (
                    <li>
                      <NavLink
                        onClick={() =>
                          item.id == 2 || item.id == 3 || item.id == 4
                            ? this.props.changeMenuFalse()
                            : this.props.changeMenuTrue()
                        }
                        to={
                          item.id == 2 || item.id == 3 || item.id == 4
                            ? '/home'
                            : '/page/' + item.id
                        }
                        activeClassName="active"
                      >
                        {item.name} <IoChevronDown size={12} />
                      </NavLink>
                      <div className="sub_menu">
                        <ul>
                          {this.state.menu
                            .filter((item2) => item2.parent_id == item.id)
                            .map((item2) => (
                              <li>
                                <NavLink
                                  onClick={() => this.props.changeMenuTrue()}
                                  to={'/page/' + item2.id}
                                  activeClassName="active"
                                >
                                  {item2.name}
                                </NavLink>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
